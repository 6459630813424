export const patients = [
    {
        id: '1',
        healthId: 'Hk123456',
        name: 'John Doe',
        date: '2025-01-25',
    },
    {
        id: '2',
        healthId: 'Hk123457',
        name: 'Jane Smith',
        date: '2025-01-25',
    },
    {
        id: '3',
        healthId: 'Hk123458',
        name: 'Alice Johnson',
        date: '2025-01-25',
    },
    {
        id: '4',
        healthId: 'Hk123459',
        name: 'Bob Brown',
        date: '2025-01-25',
    },
    {
        id: '5',
        healthId: 'Hk123460',
        name: 'Charlie Davis',
        date: '2025-01-25',
    },
    {
        id: '6',
        healthId: 'Hk123461',
        name: 'Diana Evans',
        date: '2025-01-26',
    },
    {
        id: '7',
        healthId: 'Hk123462',
        name: 'Eve Foster',
        date: '2025-01-26',
    },
    {
        id: '8',
        healthId: 'Hk123463',
        name: 'Frank Green',
        date: '2025-01-26',
    },
    {
        id: '9',
        healthId: 'Hk123464',
        name: 'Grace Harris',
        date: '2025-01-26',
    },
    {
        id: '66f3c2fc4143e01fba419cb9',
        healthId: 'Hk123465',
        name: 'Hank Irving',
        date: '2025-01-26',
    },
    {
        id: '11',
        healthId: 'Hk123466',
        name: 'Ivy Johnson',
        date: '2025-01-27',
    },
    {
        id: '12',
        healthId: 'Hk123467',
        name: 'Jack King',
        date: '2025-01-27',
    },
    {
        id: '13',
        healthId: 'Hk123468',
        name: 'Karen Lee',
        date: '2025-01-27',
    },
    {
        id: '14',
        healthId: 'Hk123469',
        name: 'Leo Martin',
        date: '2025-01-27',
    },
    {
        id: '15',
        healthId: 'Hk123470',
        name: 'Mona Nelson',
        date: '2025-01-27',
    },
    {
        id: '16',
        healthId: 'Hk123471',
        name: 'Nina Owens',
        date: '2025-01-28',
    },
    {
        id: '17',
        healthId: 'Hk123472',
        name: 'Oscar Perry',
        date: '2025-01-28',
    },
    {
        id: '18',
        healthId: 'Hk123473',
        name: 'Paul Quinn',
        date: '2025-01-28',
    },
    {
        id: '19',
        healthId: 'Hk123474',
        name: 'Quincy Roberts',
        date: '2025-01-28',
    },
    {
        id: '20',
        healthId: 'Hk123475',
        name: 'Rachel Scott',
        date: '2025-01-28',
    },
    {
        id: '21',
        healthId: 'Hk123476',
        name: 'Sam Taylor',
        date: '2025-01-29',
    },
    {
        id: '22',
        healthId: 'Hk123477',
        name: 'Tina Underwood',
        date: '2025-01-29',
    },
    {
        id: '23',
        healthId: 'Hk123478',
        name: 'Uma Vance',
        date: '2025-01-29',
    },
    {
        id: '24',
        healthId: 'Hk123479',
        name: 'Victor White',
        date: '2025-01-29',
    },
    {
        id: '25',
        healthId: 'Hk123480',
        name: 'Wendy Xander',
        date: '2025-01-29',
    },
    {
        id: '26',
        healthId: 'Hk123481',
        name: 'Xander Young',
        date: '2025-01-30',
    },
    {
        id: '27',
        healthId: 'Hk123482',
        name: 'Yara Zane',
        date: '2025-01-30',
    },
    {
        id: '28',
        healthId: 'Hk123483',
        name: 'Zane Adams',
        date: '2025-01-30',
    },
    {
        id: '29',
        healthId: 'Hk123484',
        name: 'Amy Baker',
        date: '2025-01-30',
    },
    {
        id: '30',
        healthId: 'Hk123485',
        name: 'Brian Clark',
        date: '2025-01-30',
    },
    {
        id: '31',
        healthId: 'Hk123486',
        name: 'Cathy Davis',
        date: '2025-01-31',
    },
    {
        id: '32',
        healthId: 'Hk123487',
        name: 'David Evans',
        date: '2025-01-31',
    },
    {
        id: '33',
        healthId: 'Hk123488',
        name: 'Ella Foster',
        date: '2025-01-31',
    },
    {
        id: '34',
        healthId: 'Hk123489',
        name: 'Frank Green',
        date: '2025-01-31',
    },
    {
        id: '35',
        healthId: 'Hk123490',
        name: 'Grace Harris',
        date: '2025-01-31',
    },
    {
        id: '36',
        healthId: 'Hk123491',
        name: 'Hank Irving',
        date: '2025-02-01',
    },
    {
        id: '37',
        healthId: 'Hk123492',
        name: 'Ivy Johnson',
        date: '2025-02-01',
    },
    {
        id: '38',
        healthId: 'Hk123493',
        name: 'Jack King',
        date: '2025-02-01',
    },
    {
        id: '39',
        healthId: 'Hk123494',
        name: 'Karen Lee',
        date: '2025-02-01',
    },
    {
        id: '40',
        healthId: 'Hk123495',
        name: 'Leo Martin',
        date: '2025-02-01',
    },
    {
        id: '41',
        healthId: 'Hk123496',
        name: 'Mona Nelson',
        date: '2025-02-02',
    },
    {
        id: '42',
        healthId: 'Hk123497',
        name: 'Nina Owens',
        date: '2025-02-02',
    },
    {
        id: '43',
        healthId: 'Hk123498',
        name: 'Oscar Perry',
        date: '2025-02-02',
    },
    {
        id: '44',
        healthId: 'Hk123499',
        name: 'Paul Quinn',
        date: '2025-02-02',
    }
]